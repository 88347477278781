<template>
  <div class="text-detail__body">
    <div class="her-2__banner">
      <picture>
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/banner_mob.jpg
          "
          media="(max-width: 767px)"
        />
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/banner_tab.jpg
          "
          media="(max-width: 1219px)"
        />
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/banner.jpg
          "
          media="(min-width: 1220px)"
        />
        <img
          src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/banner.jpg"
          class="her-2__banner-img"
        />
      </picture>
    </div>
    <div class="nf-other">
      <div class="nf-other__container mb-12">
        <div class="nf-other__tabs">
          <div
            class="nf-other__tabs-btn"
            @click="changeTab(0)"
            :class="{ active: activeTab === 0 }"
          >
            <div class="nf-other__tabs-btn_wrap">
              <div class="nf-other__tabs-icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="19.5" stroke="#5900C6" />
                  <g clip-path="url(#clip0_2744_2082)">
                    <path
                      d="M27.6133 22.2625C27.3657 22.2625 27.165 22.4631 27.165 22.7108V26.6911C27.1641 27.4336 26.5626 28.0353 25.8201 28.036H13.2415C12.499 28.0353 11.8975 27.4336 11.8966 26.6911V15.009C11.8975 14.2667 12.499 13.6651 13.2415 13.6642H17.2219C17.4695 13.6642 17.6702 13.4635 17.6702 13.2159C17.6702 12.9684 17.4695 12.7676 17.2219 12.7676H13.2415C12.0041 12.769 11.0014 13.7717 11 15.009V26.6913C11.0014 27.9286 12.0041 28.9313 13.2415 28.9328H25.8201C27.0575 28.9313 28.0602 27.9286 28.0616 26.6913V22.7108C28.0616 22.4631 27.8609 22.2625 27.6133 22.2625Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M27.8841 11.6143C27.0962 10.8265 25.8189 10.8265 25.0311 11.6143L17.0332 19.6122C16.9785 19.6669 16.9388 19.7349 16.9182 19.8095L15.8664 23.6066C15.8452 23.683 15.8447 23.7637 15.8648 23.8405C15.885 23.9172 15.9252 23.9872 15.9813 24.0433C16.0374 24.0994 16.1074 24.1396 16.1841 24.1598C16.2608 24.18 16.3416 24.1795 16.418 24.1583L20.2151 23.1064C20.2897 23.0857 20.3577 23.0461 20.4124 22.9914L28.41 14.9934C29.1967 14.205 29.1967 12.9288 28.41 12.1404L27.8841 11.6143ZM18.01 19.9036L24.5557 13.3578L26.6667 15.4688L20.1209 22.0146L18.01 19.9036ZM17.5883 20.7497L19.2748 22.4364L16.942 23.0828L17.5883 20.7497ZM27.7762 14.3594L27.3007 14.8348L25.1895 12.7236L25.6652 12.2482C26.1028 11.8106 26.8124 11.8106 27.2499 12.2482L27.7762 12.7742C28.2131 13.2124 28.2131 13.9215 27.7762 14.3594Z"
                      fill="#5900C6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2744_2082">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(11 11)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="d-none d-lg-inline">
                HER2-слабоположительный статус
              </div>
              <div class="d-lg-none">HER2-слабо-положительный статус</div>
            </div>
          </div>
          <div
            class="nf-other__tabs-btn"
            @click="changeTab(1)"
            :class="{ active: activeTab === 1 }"
          >
            <div class="nf-other__tabs-btn_wrap">
              <div class="nf-other__tabs-icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="19.5" stroke="#5900C6" />
                  <path
                    d="M20.2312 13.8892C21.6598 13.8892 22.8179 12.7311 22.8179 11.3025C22.8179 9.87391 21.6598 8.71582 20.2312 8.71582C18.8026 8.71582 17.6445 9.87391 17.6445 11.3025C17.6445 12.7311 18.8026 13.8892 20.2312 13.8892Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M28.7248 22.853C30.1534 22.853 31.3115 21.6949 31.3115 20.2664C31.3115 18.8378 30.1534 17.6797 28.7248 17.6797C27.2963 17.6797 26.1382 18.8378 26.1382 20.2664C26.1382 21.6949 27.2963 22.853 28.7248 22.853Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M20.2312 31.2847C21.6598 31.2847 22.8179 30.1266 22.8179 28.698C22.8179 27.2694 21.6598 26.1113 20.2312 26.1113C18.8026 26.1113 17.6445 27.2694 17.6445 28.698C17.6445 30.1266 18.8026 31.2847 20.2312 31.2847Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M11.6272 27.4888C13.0558 27.4888 14.2139 26.3307 14.2139 24.9021C14.2139 23.4735 13.0558 22.3154 11.6272 22.3154C10.1986 22.3154 9.04053 23.4735 9.04053 24.9021C9.04053 26.3307 10.1986 27.4888 11.6272 27.4888Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M11.6272 17.3735C13.0558 17.3735 14.2139 16.2154 14.2139 14.7869C14.2139 13.3583 13.0558 12.2002 11.6272 12.2002C10.1986 12.2002 9.04053 13.3583 9.04053 14.7869C9.04053 16.2154 10.1986 17.3735 11.6272 17.3735Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M20.2266 21.3204C21.3802 21.3204 22.3155 20.3851 22.3155 19.2315C22.3155 18.0778 21.3802 17.1426 20.2266 17.1426C19.0729 17.1426 18.1377 18.0778 18.1377 19.2315C18.1377 20.3851 19.0729 21.3204 20.2266 21.3204Z"
                    stroke="#5900C6"
                  />
                  <path d="M13.8535 13.4665L17.7335 11.9688" stroke="#5900C6" />
                  <path d="M22.0444 13.1514L26.88 18.4536" stroke="#5900C6" />
                  <path d="M27.1424 22.3115L22.0757 26.8849" stroke="#5900C6" />
                  <path d="M13.0488 16.9463L18.6266 26.6707" stroke="#5900C6" />
                  <path d="M13.9155 26.1064L17.6444 27.8353" stroke="#5900C6" />
                  <path d="M12.5957 22.5023L18.8313 13.4756" stroke="#5900C6" />
                  <path d="M14.0093 15.7998L18.3737 18.2665" stroke="#5900C6" />
                  <path d="M22.3198 19.4668L26.1376 19.7557" stroke="#5900C6" />
                  <path d="M20.2266 21.3242L20.231 26.1064" stroke="#5900C6" />
                </svg>
              </div>
              <div class="d-none d-lg-inline">
                <span>Исследование DESTINY-Breast04 </span> Дизайн исследования
              </div>
              <div class="d-lg-none">DB04 <br />Дизайн исследования</div>
            </div>
          </div>
          <div
            class="nf-other__tabs-btn"
            @click="changeTab(2)"
            :class="{ active: activeTab === 2 }"
          >
            <div class="nf-other__tabs-btn_wrap">
              <div class="nf-other__tabs-icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="19.5" stroke="#5900C6" />
                  <path
                    d="M20.2312 13.8892C21.6598 13.8892 22.8179 12.7311 22.8179 11.3025C22.8179 9.87391 21.6598 8.71582 20.2312 8.71582C18.8026 8.71582 17.6445 9.87391 17.6445 11.3025C17.6445 12.7311 18.8026 13.8892 20.2312 13.8892Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M28.7248 22.853C30.1534 22.853 31.3115 21.6949 31.3115 20.2664C31.3115 18.8378 30.1534 17.6797 28.7248 17.6797C27.2963 17.6797 26.1382 18.8378 26.1382 20.2664C26.1382 21.6949 27.2963 22.853 28.7248 22.853Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M20.2312 31.2847C21.6598 31.2847 22.8179 30.1266 22.8179 28.698C22.8179 27.2694 21.6598 26.1113 20.2312 26.1113C18.8026 26.1113 17.6445 27.2694 17.6445 28.698C17.6445 30.1266 18.8026 31.2847 20.2312 31.2847Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M11.6272 27.4888C13.0558 27.4888 14.2139 26.3307 14.2139 24.9021C14.2139 23.4735 13.0558 22.3154 11.6272 22.3154C10.1986 22.3154 9.04053 23.4735 9.04053 24.9021C9.04053 26.3307 10.1986 27.4888 11.6272 27.4888Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M11.6272 17.3735C13.0558 17.3735 14.2139 16.2154 14.2139 14.7869C14.2139 13.3583 13.0558 12.2002 11.6272 12.2002C10.1986 12.2002 9.04053 13.3583 9.04053 14.7869C9.04053 16.2154 10.1986 17.3735 11.6272 17.3735Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M20.2266 21.3204C21.3802 21.3204 22.3155 20.3851 22.3155 19.2315C22.3155 18.0778 21.3802 17.1426 20.2266 17.1426C19.0729 17.1426 18.1377 18.0778 18.1377 19.2315C18.1377 20.3851 19.0729 21.3204 20.2266 21.3204Z"
                    stroke="#5900C6"
                  />
                  <path d="M13.8535 13.4665L17.7335 11.9688" stroke="#5900C6" />
                  <path d="M22.0444 13.1514L26.88 18.4536" stroke="#5900C6" />
                  <path d="M27.1424 22.3115L22.0757 26.8849" stroke="#5900C6" />
                  <path d="M13.0488 16.9463L18.6266 26.6707" stroke="#5900C6" />
                  <path d="M13.9155 26.1064L17.6444 27.8353" stroke="#5900C6" />
                  <path d="M12.5957 22.5023L18.8313 13.4756" stroke="#5900C6" />
                  <path d="M14.0093 15.7998L18.3737 18.2665" stroke="#5900C6" />
                  <path d="M22.3198 19.4668L26.1376 19.7557" stroke="#5900C6" />
                  <path d="M20.2266 21.3242L20.231 26.1064" stroke="#5900C6" />
                </svg>
              </div>

              <div class="d-none d-lg-inline">
                <span>Исследование DESTINY-Breast04 </span> Поданализ
              </div>
              <div class="d-lg-none">DB04 <br />Поданализ</div>
            </div>
          </div>
        </div>
        <div class="nf-other__content">
          <div class="nf-other__content-block" v-show="activeTab === 0">
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                ~60 % пациентов с РМЖ могут иметь слабоположительный уровень
                экспрессии HER2 (HER2-low)<sup>2-4</sup>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/1.jpg"
                />
              </div>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                Большинство пациентов с HER2-отрицательным РМЖ относятся к
                HER2-low подтипу<sup>5</sup>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/2.jpg"
                />
              </div>
              <div class="text-1">
                <ul>
                  <li>
                    Проанализировано 13 независимых баз данных пациентов с HER2-
                    РМЖ (N=3,689) для сравнения клинических характеристик
                    пациентов с HER2-слабоположительным РМЖ  (ИГХ 1+,2+/ISH-) по
                    сравнению с HER2 ИГХ 0 РМ
                  </li>
                  <li>
                    HER2-low опухоли достоверно чаще относились к ГР+ в
                    сравнении с ГР- (ТНРМЖ) (65.4% vs 36.5%; P&lt;0.001)
                  </li>
                  <li>
                    ~68% HER2-low РМЖ имели оценку ИГХ1+, независимо от статуса
                    гормональных рецепторов
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                Образцы с окрашиванием ИГХ 1+ или 2+/ISH- рассматриваются как
                HER2 - слабоположительные<sup>6</sup>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/3.jpg"
                />
              </div>
            </div>
            <div class="text-3 mb-4" style="color: #000">
              Список сокращений и условных обозначений:
            </div>
            <div class="text-1 mb-8">
              HER2 – рецептор эпидермального фактора роста человека 2-го типа
              (human epidermal growth factor receptor 2);
              <br /><br />
              РМЖ – рак молочной железы;
              <br /><br />
              мВБП – медиана выживаемости без прогрессирования;
              <br /><br />
              ЧОО – частота объективного ответа;
              <br /><br />
              T-DM1 — трастузумаб эмтанзин.
            </div>
            <div class="above-title-1 mb-4" style="color: #830051">
              Источники
            </div>
            <ol style="list-style-position: inside; padding-left: 0">
              <li>
                Modi S et.al. N Engl J Med. 2022 Jul 7;387(1):9-20. doi:
                10.1056/NEJMoa2203690. Epub 2022 Jun 5. PMID: 35665782
              </li>
              <li>Tarantino P, et al. J Clin Oncol. 2020;38(17):1951-1962  </li>
              <li>
                Horisawa N et al. Breast Cancer. 2022 Mar;29(2):234-241. doi:
                10.1007/s12282-021-01303-3. Epub 2021 Oct 7. PMID: 34622383. 3.
                Wolff AC, et al. J Clin Oncol. 2018;36(20):2105-2122
              </li>
              <li>Wolff AC, et al. J Clin Oncol. 2018;36(20):2105-2122</li>
              <li>Schettini F, et al. NPJ Breast Cancer. 2021;7(1):1. </li>
              <li>
                Tarantino P. et al. ESMO Expert Consensus Statements (ECS) on
                the definition, diagnosis, and management of HER2-low breast
                cancer, Annals of Oncology (2023), doi:
                <a
                  href="https://doi.org/10.1016/j.annonc.2023.05.008"
                  target="_blank"
                  >https://doi.org/10.1016/j.annonc.2023.05.008</a
                >
                .
              </li>
            </ol>
          </div>
          <div class="nf-other__content-block" v-show="activeTab === 1">
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                <span style="color: #ff7b25"
                  >Открытое многоцентровое исследование III фазы
                  (NCT03734029)<sup>1</sup></span
                >:
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/4.jpg"
                />
              </div>
            </div>

            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                Исследование DESTINY-Breast04. Предшествующая терапия<sup
                  >1,2</sup
                >
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1 mb-4">
                <ul>
                  <li>
                    Медиана количества линий системной терапии составила 3, а
                    медиана линий химиотерапии 1
                  </li>
                  <li>
                    мВБП в группе Энхерту была почти в 2 раза выше чем в группе
                    ТВВ 9,9 мес  vs 5,1 мес
                  </li>
                </ul>
              </div>
              <div class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/5.jpg"
                />
              </div>
            </div>

            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                В группе ЭНХЕРТУ частота объективного ответа у пациентов с
                HER2-слабоположительным  статусом была
                <span style="color: #ff7b25">в 3 раза выше</span> по сравнению с
                химиотерапией по выбору врача<sup>1</sup>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div v-if="activePicture['6'] === 0" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/6-1.jpg"
                />
              </div>
              <div v-if="activePicture['6'] === 1" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/6-2.jpg"
                />
              </div>
              <div v-if="activePicture['6'] === 2" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/6-3.jpg"
                />
              </div>
              <div class="d-md-flex mt-xs-4">
                <div
                  class="button button_pink px-4"
                  @click="activePicture['6'] = 0"
                  v-if="activePicture['6'] === 1"
                >
                  <svg
                    class="mr-2"
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 5L2 12.5L9.5 20"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                  Все пациенты ГР+ и ГР- HER2-low
                </div>
                <div
                  class="button button_pink px-4"
                  @click="activePicture['6'] = 1"
                  v-if="activePicture['6'] === 2"
                >
                  <svg
                    class="mr-2"
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 5L2 12.5L9.5 20"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                  Группа пациентов ГР+
                </div>
                <div
                  v-if="activePicture['6'] === 0"
                  @click="activePicture['6'] = 1"
                  class="ml-auto mt-xs-4 button button_empty-pink px-4"
                >
                  Группа пациентов ГР+
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 4.5L17 12L9.5 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
                <div
                  v-if="activePicture['6'] === 1"
                  @click="activePicture['6'] = 2"
                  class="ml-auto mt-xs-4 button button_empty-pink px-4"
                >
                  Группа пациентов ГР-
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 4.5L17 12L9.5 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                У пациентов с HER2-слабоположительным статусом, получавших
                ЭНХЕРТУ, мВБП была почти
                <span style="color: #ff7b25">в 2 раза выше</span> по сравнению с
                химиотерапией по выбору врача<sup>1</sup>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1 mb-4">
                DESTINY-Breast04: Выживаемость без прогрессирования (по оценке
                BICR)
              </div>
              <div v-if="activePicture['7'] === 0" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/7-1.jpg"
                />
              </div>
              <div v-if="activePicture['7'] === 1" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/7-2.jpg"
                />
              </div>
              <div v-if="activePicture['7'] === 2" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/7-3.jpg"
                />
              </div>
              <div class="d-md-flex mt-xs-4">
                <div
                  class="button button_pink px-4"
                  @click="activePicture['7'] = 0"
                  v-if="activePicture['7'] === 1"
                >
                  <svg
                    class="mr-2"
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 5L2 12.5L9.5 20"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                  Все пациенты ГР+ и ГР-
                </div>
                <div
                  class="button button_pink px-4"
                  @click="activePicture['7'] = 1"
                  v-if="activePicture['7'] === 2"
                >
                  <svg
                    class="mr-2"
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 5L2 12.5L9.5 20"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                  Группа пациентов ГР+
                </div>
                <div
                  v-if="activePicture['7'] === 0"
                  @click="activePicture['7'] = 1"
                  class="ml-auto mt-xs-4 button button_empty-pink px-4"
                >
                  Группа пациентов ГР+
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 4.5L17 12L9.5 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
                <div
                  v-if="activePicture['7'] === 1"
                  @click="activePicture['7'] = 2"
                  class="ml-auto mt-xs-4 button button_empty-pink px-4"
                >
                  Пациенты ГР-
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 4.5L17 12L9.5 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                Медиана выживаемости без второго прогрессирования в группе
                Энхерту превысила 15 месяцев<sup>3</sup>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div v-if="activePicture['8'] === 0" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/8-1.jpg"
                />
              </div>
              <div v-if="activePicture['8'] === 1" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/8-2.jpg"
                />
              </div>
              <div class="d-md-flex mt-xs-4">
                <div
                  class="button button_pink px-4"
                  @click="activePicture['8'] = 0"
                  v-if="activePicture['8'] === 1"
                >
                  <svg
                    class="mr-2"
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 5L2 12.5L9.5 20"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                  Все пациенты ГР+ и ГР-
                </div>

                <div
                  v-if="activePicture['8'] === 0"
                  @click="activePicture['8'] = 1"
                  class="ml-auto mt-xs-4 button button_empty-pink px-4"
                >
                  Группа пациентов ГР+
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 4.5L17 12L9.5 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                У пациентов с HER2-слабоположительным статусом, получавших
                ЭНХЕРТУ, общая выживаемость составила
                <span style="color: #ff7b25">почти 2 года<sup>1</sup></span>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1 mb-4">
                DESTINY-Breast04: Общая выживаемость
              </div>
              <div v-if="activePicture['9'] === 0" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/9-1.jpg"
                />
              </div>
              <div v-if="activePicture['9'] === 1" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/9-2.jpg"
                />
              </div>
              <div v-if="activePicture['9'] === 2" class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/9-3.jpg"
                />
              </div>
              <div class="d-md-flex mt-xs-4">
                <div
                  class="button button_pink px-4"
                  @click="activePicture['9'] = 0"
                  v-if="activePicture['9'] === 1"
                >
                  <svg
                    class="mr-2"
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 5L2 12.5L9.5 20"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                  Все пациенты ГР+ и ГР- HER2-low
                </div>
                <div
                  class="button button_pink px-4"
                  @click="activePicture['9'] = 1"
                  v-if="activePicture['9'] === 2"
                >
                  <svg
                    class="mr-2"
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 5L2 12.5L9.5 20"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                  Группа пациентов ГР+
                </div>
                <div
                  v-if="activePicture['9'] === 0"
                  @click="activePicture['9'] = 1"
                  class="ml-auto mt-xs-4 button button_empty-pink px-4"
                >
                  Группа пациентов ГР+
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 4.5L17 12L9.5 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
                <div
                  v-if="activePicture['9'] === 1"
                  @click="activePicture['9'] = 2"
                  class="ml-auto mt-xs-4 button button_empty-pink px-4"
                >
                  Группа пациентов ГР-
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 4.5L17 12L9.5 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                ЭНХЕРТУ продемонстрировал эффективность в группе пациентов с
                HER2-low ГР+ мРМЖ
                <span style="color: #ff7b25"
                  >независимо от молекулярного подтипа и наличия мутаций<sup
                    >2,4</sup
                  ></span
                >
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1 mb-4">
                Исследование DESTINY-Breast04:
                <br />
                <br />
                Анализ биомаркеров: ЧОО в соответствие с базовыми показателями
              </div>
              <div class="overflow-image">
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/10.jpg"
                />
              </div>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                Обзор международных рекомендаций:
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1 mb-4">
                Трастузумаб дерукстекан включен в международные рекомендации в
                качестве опции терапии для пациентов с HER2-слабоположительным
                мРМЖ<sup>5,6</sup>:
              </div>

              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/11_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/11_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/11.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/11.jpg"
                />
              </picture>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                ЭНХЕРТУ – открывает дополнительную возможность терапии пациентов
                с HER2-слабоположительным мРМЖ<sup>1</sup>
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1 mb-4">
                Препарату ЭНХЕРТУ FDA присвоило статус
                <span style="color: #ff7b25">терапии прорыва</span>  у пациентов
                HER2-слабоположительным мРМЖ <sup>7</sup>:
              </div>

              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/12_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/12_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/12.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/12.jpg"
                />
              </picture>
              <div class="pa-5 pt-0" style="background-color: #fff">
                <div class="text-1 ">
                  ЭНХЕРТУ <span style="color: #ff7b25">продемонстрировал более высокую эффективность</span> по сравнению <span style="color: #ff7b25">с химиотерапией</span> в группе по выбору врача у пациентов с HER2-слабоположительным мРМЖ<sup>2</sup>
                </div>
                

              </div>
            </div>

            <div class="text-3 mb-4" style="color: #000">
              Список сокращений и условных обозначений:
            </div>
            <div class="text-1 mb-8">
              HER2 – рецептор эпидермального фактора роста человека 2-го типа (human epidermal growth factor receptor 2); 
<br><br>
ГР-гормональные рецепторы;
<br><br>
мРМЖ – метастатический рак молочной железы;
<br><br>
ВБП — выживаемость без прогрессирования; 
<br><br>
ЧОО — частота объективного ответа на лечение; 
<br><br>
ОВ — общая выживаемость; 
<br><br>
BICR — независимая централизованная оценка в слепом режиме; 
<br><br>
ИГХ — иммуногистохимическое исследование; 
<br><br>
ISH — гибридизация in situ; 
<br><br>
мВБП – медиана выживаемости без прогрессирования;
<br><br>
мес. – месяцы; 
<br><br>
ДИ - доверительный интервал; 
<br><br>
ОР – отношение рисков; 
<br><br>
T-DXd – трастузумаб дерукстекан; 
<br><br>
ПО — полный ответ; 
<br><br>
мВБП2 – медиана выживаемости без второго прогрессирования; 
<br><br>
мОВ - медиана общей выживаемости.
            </div>
            <div class="above-title-1 mb-4" style="color: #830051">
              Источники
            </div>
            <ol style="list-style-position: inside; padding-left: 0">
             <li>Modi S et.al. N Engl J Med. 2022 Jul 7;387(1):9-20. doi: 10.1056/NEJMoa2203690. Epub 2022 Jun 5. PMID: 35665782</li>
             <li>Modi S et al. Представлено на съезде Американского общества клинической онкологии (ASCO) 2022; июнь 2022 г., LBA3.</li>
             <li>Modi S et al. Presented at the European Society for Medical Oncology (ESMO) Annual Meeting; Madrid, Spain, October 20-24, 2023. Рresentation 3760</li>
             <li>Адаптировано из: Modi S et al. Presented at the American Society of Clinical Oncology (ASCO) Annual Meeting; June 2-6, 2023. Poster 1020. 2. Modi S et al. N Engl J Med. 2022;387:9-20.</li>
             <li>NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®). Breast Cancer. Version 4.2024 — July 3, 2024, Электронный ресурс: <a href="https://www.nccn.org/professionals/physician_gls/pdf/breast.pdf" target="_blank">https://www.nccn.org/professionals/physician_gls/pdf/breast.pdf</a> , дата обращения: 12.08.2024</li>
             <li>ESMO Metastatic Breast Cancer Living Guidelines, v1.1 May 2023, Электронный ресурс: <a href="https://www.esmo.org/living-guidelines/esmo-metastatic-breast-cancer-living-guideline" target="_blank">https://www.esmo.org/living-guidelines/esmo-metastatic-breast-cancer-living-guideline</a>  , дата обращения: 25.05.2023; </li>
             <li>Электронный ресурс <a href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-fam-trastuzumab-deruxtecan-nxki-her2-low-breast-cancer" target="_blank">https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-fam-trastuzumab-deruxtecan-nxki-her2-low-breast-cancer</a>   Дата доступа 29.05.2023)</li>
            </ol>
          </div>
          <div class="nf-other__content-block" v-show="activeTab === 2">
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">
                Энхерту продемонстрировал эффективность в отношении интракраниальных очагов у пациентов с мРМЖ с низкой экспрессией HER2
              </div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="overflow-image mb-4">
                <img
                 
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/13.jpg"
                />
              </div>
              <div class="overflow-image mb-4">
                <img
                  
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/14.jpg"
                />
              </div>
              <div class="overflow-image mb-4">
                <img
                
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/15.jpg"
                />
              </div>
              <div class="overflow-image mb-4">
                <img
                  
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/16.jpg"
                />
              </div>
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/17_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/17_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/17.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2-low/17.jpg"
                />
              </picture>
            </div>

            <div class="text-1 mb-4">
              *Дата среза данных 21 мая 2021 г.<br />
              **Дата среза данных 25 июля 2022 г.
            </div>
            <div class="text-3 mb-4" style="color: #000">
              Список сокращений и условных обозначений:
            </div>
            <div class="text-1 mb-8">
              HER2 –  рецептор эпидермального фактора роста человека 2 типа
<br><br>
HER2-low – HER2-слабоположительный (ИГХ 1+, 2+/ISH-)
<br><br>
ИГХ – иммуногистохимия
<br><br>
ГР – гормональные рецепторы
<br><br>
РМЖ- рак молочной железы
<br><br>
мРМЖ – метастатический рак молочной железы
<br><br>
ISH — гибридизация in situ
<br><br>
ТВВ – терапия по выбору врача
<br><br>
ЧОО – частота объективного ответа
<br><br>
ПО – полный ответ
<br><br>
мПро – медиана продолжительности ответа
<br><br>
ВБП (по оценке BICR)-выживаемость без прогрессирования по заслепленной независимой центральной экспертной оценке
<br><br>
мВБП-медиана выживаемости без прогрессирования
<br><br>
ДИ – доверительный интервал
<br><br>
ТВВ- терапия по выбору врача
<br><br>
T-DXd – трастузумаб дерукстекан
<br><br>
T-DM1 – трастузумаб эмтанзин
<br><br>
ГР – гормональные рецепторы
<br><br>
ОР – отношение рисков
<br><br>
ОВ – общая выживаемость
<br><br>
мОВ – медиана общей выживаемости
<br><br>
ПрО – продолжительность ответа
<br><br>
NCCN – (national comprehensive cancer network) Национальная комплексная онкологическая сеть
<br><br>
ESMO – (EUROPEAN SOCIETY FOR MEDICAL ONCOLOGY) Европейское общество медицинской онкологии 
<br><br>
пЧОО – подтверждённая частота объективного ответа
<br><br>
ЧКП – частота клинической пользы
<br><br>
ЧКЗ – частота контроля заболевания
<br><br>
ИК – интракраниальный
<br><br>
ВБП – ЦНС-выживаемость без прогрессирования в центральной нервной системе
<br><br>
СЗ – стабилизация заболевания
<br><br>
ПЗ – прогрессирование заболевания
            </div>
            <div class="above-title-1 mb-4" style="color: #830051">
              Источники
            </div>
            <ol style="list-style-position: inside; padding-left: 0">
              <li>Tsurutani J et al. Presented at the European Society for Medical Oncology (ESMO) Annual Meeting; Madrid, Spain, October 20-24, 2023. Poster 388P.</li>
              <li>J. Jin, et al. Journal of Translational Medicine (2023) 21:360, <a href="https://doi.org/10.1186/s12967-023-04076-9" target="_blank">https://doi.org/10.1186/s12967-023-04076-9</a> </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Her2_content",
  data: () => ({
    activeTab: 0,
    activePicture: {
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  }),
  methods: {
    changeTab(index) {
      if (
        this.user &&
        !Object.keys(this.user).length &&
        !this.$route.query.access
      ) {
        this.popup = true;
      } else {
        this.activeTab = index;
      }
    },
    scrollToTabTopAndChangeTab(index) {
      this.changeTab(index);
      if (
        (this.user && Object.keys(this.user).length) ||
        this.$route.query.access
      ) {
        setTimeout(() => {
          const tabBtn = document.querySelector(
            `.nf-other__tabs-btn:nth-child(${index + 1})`
          );
          if (tabBtn) {
            const scrollHeight = this.getScrollHeight(); // Получить высоту прокрутки в зависимости от ширины экрана
            const topPosition =
              tabBtn.getBoundingClientRect().top +
              window.pageYOffset -
              scrollHeight;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
          }
        }, 100); // Задержка для обеспечения корректной смены таба перед прокруткой
      }
    },
    getScrollHeight() {
      if (window.innerWidth >= 1200) {
        return 150; // Прокрутка для широкого экрана
      } else if (window.innerWidth >= 768) {
        return 30; // Прокрутка для узкого экрана
      } else {
        return 10;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail__body {
  // margin-top: 64px;
  @media screen and (max-width: 1219px) {
    // margin-top: 32px;
  }
}

.overflow-image {
  @media screen and (max-width: 767px) {
    padding-bottom: 15px;
    overflow: auto;
    // -ms-overflow-style: scrollbar;
    // scrollbar-color: #d2d2d2 transparent;
    // scrollbar-width: thin;
    background-color: #fff;

    & img {
      width: 200%;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d2d2d2;
      border-radius: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar--track-piece {
      border-radius: 5px;
      background-color: #ebefee;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #ebefee;
      border: 3px solid #ebefee;
    }

    &::-moz-scrollbar-button:decrement,
    &::-moz-scrollbar-button:increment,
    &::-moz-scrollbar-button:vertical:start:decrement,
    &::-moz-scrollbar-button:vertical:end:increment,
    &::-moz-scrollbar-button:horizontal:start:decrement,
    &::-moz-scrollbar-button:horizontal:end:increment,
    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:horizontal:start:decrement,
    &::-webkit-scrollbar-button:horizontal:end:increment {
      width: 0px !important;
      height: 0px !important;
    }
  }
}

.her-2__banner-img {
  width: 100%;
  margin-bottom: 32px;
}
.nf-other {
  &__tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-height: 98px;
    @media screen and(max-width: 1199px) {
    }
    @media screen and(max-width: 767px) {
      max-height: 150px;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      cursor: pointer;
      padding: 10px 16px;
      width: 251px;
      height: 80px;
      background-color: #f8f8f8;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #1f1f1f;

      & span {
        font-size: 12px;
        display: block;
      }
      &_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and(max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &.active {
        border: none;
        height: 98px;
        border-radius: 8px 8px 0px 0px;
        background-color: #ffebde;
        @media screen and(max-width: 767px) {
          height: 141px;
        }
      }
      @media screen and(max-width: 1199px) {
        max-width: 200px;
        font-size: 14px;
        padding: 16px;
      }
      @media screen and(max-width: 767px) {
        height: 129px;
        font-size: 12px;
        line-height: 16px;
        padding: 8px;
        font-weight: 500;
      }
    }
    &-icon {
      margin-right: 16px;
      @media screen and(max-width: 767px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
  &__content {
    &-section {
      background-color: #ffebde;
      margin-bottom: 32px;
      padding: 24px 16px;
    }

    &-section-light {
      background-color: #fff6f0;
    }
  }
}
</style>